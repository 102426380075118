import React from "react";
import "../styles/depot.css";
import { Link } from "react-router-dom";

const Depot = () => {
  return (
    <>
      <section className="depot-inner-con">
        <div
          className="text  text-center flex
      flex-col items-center justify-center capitalize pb-[200px]  text-[#0B2C07]"
          data-aos="fade-down-right"
        >
          <p className="mb-6 xl:text-xl lg:text-2xl leading-snug pt-10 md:text-xl text-2xl mt-20">
            Welcome to Tricord Farmers Depot
          </p>
          <h2 className="xl:text-5xl xl:w-[70%] lg:text-5xl md:text-5xl text-4xl text-dark leading-[5rem]">
            "Empowering Farmers, Elevating Produce: Redefining Agricultural
            Excellence."
          </h2>
          <p className="xl:text-xl mt-10 lg:text-xl xl:w-[80%]">
            At Tricord Farmers Depot, we are revolutionizing the agricultural
            value chain by bridging the gap between local farmers and the global
            market.
          </p>
        </div>
      </section>
      <section className="bg-[#F9FAF0]">
        <div
          className="service-header flex flex-col justify-center items-center text-[#0B2C07] font-semibold text-center pt-36"
          data-aos="zoom-in"
        >
          <h1 className="xl:text-6xl mb-5 text-4xl md:text-6xl">What We Do</h1>
          <p className="xl:text-xl texl-sm md:text-2xl mb-16 md:w-[70%]">
            We offer a comprehensive range of services designed to support
            businesses, farmers, and consumers with top-quality solutions
          </p>
        </div>
        <div className="what-we-do-con lg:flex gap-5">
          <div className="what-we-do-inner-con lg:w-[50%]" data-aos="zoom-in">
            <img
              src="Images/farmers.jpeg"
              alt=""
              className="rounded-[20px] h-[300px] mb-5 
                    w-[100%] "
            />
            <h2 className="text-2xl mb-4 text-[#0B2C07]">
              Purchasing Farm Produce
            </h2>
            <p className="text-xl mb-10">
              We partner directly with farmers, buying their produce at
              competitive prices and ensuring a sustainable income for their
              hard work.
            </p>
          </div>
          <div className="what-we-do-inner-con lg:w-[50%]" data-aos="zoom-in">
            <img
              src="Images/farmers2.jpeg"
              alt=""
              className="rounded-[20px] h-[300px] mb-5
                    w-[100%] "
            />
            <h2 className="text-2xl mb-4 text-[#0B2C07]">
              Offtaking Made Easy
            </h2>
            <p className="text-xl mb-10">
              As reliable offtakers, we eliminate the uncertainty in selling
              produce, giving farmers the confidence to focus on what they do
              best—farming.
            </p>
          </div>
          <div className="what-we-do-inner-con lg:w-[50%]" data-aos="zoom-in">
            <img
              src="Images/food-processing.jpg"
              alt=""
              className="rounded-[20px] h-[300px] 
                    w-[100%] mb-5"
            />
            <h2 className="text-2xl mb-4 text-[#0B2C07]">
              Exporting Excellence
            </h2>
            <p className="text-xl mb-10">
              By exporting processed goods, we open doors to new markets,
              boosting economic opportunities for farmers and contributing to
              the global agricultural ecosystem.
            </p>
          </div>
        </div>
        <div className="what-we-do-con lg:flex gap-5 pt-36">
          <div className="what-we-do-inner-con lg:w-[50%]" data-aos="zoom-in">
            <img
              src="Images/equipment.jpeg"
              alt=""
              className="rounded-[20px] h-[400px] mb-3  
                    w-[100%] "
            />
            <h2 className="text-2xl mb-3 text-[#0B2C07] mt-7">
              Empowering Farmers small Loan Access
            </h2>
            <p className="text-xl  mb-10">
              We understand the challenges of farming. Tricord Depot offers
              small loans to help farmers expand their farms, purchase better
              equipment, and increase productivity.
            </p>
          </div>
          <div className="what-we-do-inner-con lg:w-[50%]" data-aos="zoom-in">
            <img
              src="Images/food-processing.jpg"
              alt=""
              className="rounded-[20px] h-[400px] mb-3 
                    w-[100%] "
            />
            <h2 className="text-2xl mb-3 text-[#0B2C07] mt-7">
              Processing to International Standards
            </h2>
            <p className="text-xl mb-10">
              Our state-of-the-art warehouse and processing facility ensure that
              farm produce meets global quality standards, making it ready for
              export to international markets.
            </p>
          </div>
        </div>

        <div
          className="mission-statement xl:w-[70%] bg-[#F8ECC8] md:px-10 md:py-16 xl:ml-64 z-40 w-[100%] rounded-[20px] px-6 py-6  mt-36"
          data-aos="zoom-in"
        >
          <img src="Images/mission-star.svg" alt="" />
          <h2 className="xl:text-4xl mt-5 text-3xl">Our Commitment</h2>

          <p className="xl:text-2xl mt-4 leading-loose z-40 text-xl">
            At Tricord Depot, we are committed to creating a sustainable
            agricultural ecosystem that uplifts local farmers, ensures quality,
            and promotes growth. Together, we are transforming farming from
            subsistence to success.
          </p>
        </div>

        <div className="partner w-[100%]" data-aos="fade-right">
          <h1 className="md:text-4xl xl:w-[35%] mb-5 font-semibold leading-[3rem] mt-48 text-3xl">
            Partner with Us for Growth and Excellence{" "}
          </h1>
          <p className="text-xl xl:w-[80%] mb-5 leading-[2rem]">
            Whether you're a farmer seeking a reliable offtaker, a business in
            need of top-quality processed farm produce, or an exporter aiming
            for international standards, Tricord Depot is your trusted partner.
            Together, we bridge the gap between farms and global markets.
          </p>
          <h3 className="md:text-3xl  text-2xl font-semibold ">
            Ready to Work with Us?
          </h3>
          <p className="text-xl xl:w-[70%] mt-3 mb-10">
            Reach out to discuss how we can support your farming or business
            goals. Whether it's processing, financing, or exporting, your
            journey to success starts with Tricord Depot. Let’s grow together!
          </p>
          <div className="partner-btns flex items-center gap-4 pb-28">
            <Link
              className=" rounded-[20px] border-2 px-6 py-3 border-[#277A1B] text-[#277A1B] md:text-xl text-[12px]"
              to="mailto:contact@tricordglobal.com.ng"
            >
              Contact Us
            </Link>
            <div className="flex flex-col items-center justify-center "></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Depot;
