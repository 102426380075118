// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation } from "swiper/modules";
import { Link } from "react-router-dom";

const Aboutus = () => {
  return (
    <>
      <section className="hero-con  ">
        <div className="py-56" data-aos="fade-right">
          <h2 className="text-4xl text-white">About us</h2>
        </div>
      </section>
      <section className="what-we-do text-[#0B2C07] bg-[rgb(248,250,240)] pb-20">
        <div data-aos="fade-right">
          <h2 className="md:text-5xl text-center pt-20 mb-3 text-captilize text-4xl ">
            what we do
          </h2>
          <p className="md:text-2xl text-center mb-5 text-xl">
            Tricord Global operates across various sectors, including:
          </p>
        </div>

        {/* <Swiper
          navigation={true}
          modules={[Navigation]}
          className="mySwiper md:w-[80%]"
          data-aos="zoom-in"
        >
          <SwiperSlide>
            <div className="flex flex-col justify-center items-center">
              <img
                src="/Images/tricordplantain.jpeg"
                alt=""
                className="w-[100%] h-[500px] rounded-[30px]"
              />
            </div>
            <h2 className="md:text-4xl text-2xl  my-3">Tricord Food</h2>
            <p className="md:text-2xl  md:text-xl md:w-[70%] w-[100%]">
              Through Tricord Food, we produce a range of high-quality food
              products tailored for local consumption.
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col justify-center items-center">
              <img
                src="/Images/tricord-packaging.webp"
                alt=""
                className="w-[100%] h-[500px] rounded-[30px]"
              />
            </div>
            <h2 className="md:text-4xl text-2xl  my-3">Packaging Solutions</h2>
            <p className="md:text-2xl  md:text-xl md:w-[70%]">
              Providing eco-friendly, durable, and customizable packaging to
              meet the needs of businesses and consumers.{" "}
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col justify-center items-center">
              <img
                src="/Images/truck.jpg"
                alt=""
                className="w-[100%] h-[500px] rounded-[30px]"
              />
            </div>
            <h2 className="md:text-4xl text-2xl my-3">Logistics solution</h2>
            <p className="md:text-2xl  md:text-xl md:w-[70%]">
              Efficient and reliable logistics solutions to ensure timely
              delivery and transportation of products.
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col justify-center items-center">
              <img
                src="/Images/tricorddepot.webp"
                alt=""
                className="w-[100%] h-[500px] rounded-[30px]"
              />
            </div>
            <h2 className="md:text-4xl text-2xl  my-3">Farmers Depot</h2>
            <p className="md:text-2xl  md:text-xl md:w-[70%]">
              We partner directly with farmers, buying their produce at
              competitive prices and ensuring a sustainable income for their
              hard work.
            </p>
          </SwiperSlide>
        </Swiper> */}
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="mySwiper w-full md:w-[80%] mx-auto"
          data-aos="zoom-in"
        >
          <SwiperSlide>
            <div className="flex flex-col justify-center items-center">
              <img
                src="/Images/tricordplantain.jpeg"
                alt="Tricord Food"
                className="w-full h-[300px] md:h-[400px] xl:h-[500px] rounded-[30px] object-cover"
              />
            </div>
            <h2 className="text-xl md:text-3xl xl:text-4xl font-semibold my-4 text-center">
              Tricord Food
            </h2>
            <p className="text-sm md:text-lg xl:text-xl xl:w-[70%] w-full text-center mx-auto leading-relaxed">
              Through Tricord Food, we produce a range of high-quality food
              products tailored for local consumption.
            </p>
          </SwiperSlide>

          <SwiperSlide>
            <div className="flex flex-col justify-center items-center">
              <img
                src="/Images/tricord-packaging.webp"
                alt="Packaging Solutions"
                className="w-full h-[300px] md:h-[400px] xl:h-[500px] rounded-[30px] object-cover"
              />
            </div>
            <h2 className="text-xl md:text-3xl xl:text-4xl font-semibold my-4 text-center">
              Packaging Solutions
            </h2>
            <p className="text-sm md:text-lg xl:text-xl xl:w-[70%] w-full text-center mx-auto leading-relaxed">
              Providing eco-friendly, durable, and customizable packaging to
              meet the needs of businesses and consumers.
            </p>
          </SwiperSlide>

          <SwiperSlide>
            <div className="flex flex-col justify-center items-center">
              <img
                src="/Images/logihero.jpg"
                alt="Logistics Solution"
                className="w-full h-[300px] md:h-[400px] xl:h-[500px] rounded-[30px] object-cover"
              />
            </div>
            <h2 className="text-xl md:text-3xl xl:text-4xl font-semibold my-4 text-center">
              Logistics Solution
            </h2>
            <p className="text-sm md:text-lg xl:text-xl xl:w-[70%] w-full text-center mx-auto leading-relaxed">
              Efficient and reliable logistics solutions to ensure timely
              delivery and transportation of products.
            </p>
          </SwiperSlide>

          <SwiperSlide>
            <div className="flex flex-col justify-center items-center">
              <img
                src="/Images/tricorddepot.jpg"
                alt="Farmers Depot"
                className="w-full h-[300px] md:h-[400px] xl:h-[500px] rounded-[30px] object-cover"
              />
            </div>
            <h2 className="text-xl md:text-3xl xl:text-4xl font-semibold my-4 text-center">
              Farmers Depot
            </h2>
            <p className="text-sm md:text-lg xl:text-xl xl:w-[70%] w-full text-center mx-auto leading-relaxed">
              We partner directly with farmers, buying their produce at
              competitive prices and ensuring a sustainable income for their
              hard work.
            </p>
          </SwiperSlide>
        </Swiper>

        <section className="who">
          <div data-aos="fade-right">
            <h2 className="md:text-5xl text-2xl mt-[100px] mb-5 ">
              Who we are
            </h2>
            <p className="md:text-xl text-xl mb-10 leading-normal">
              At Tricord Global, we are a forward-thinking company driven by
              innovation, sustainability, and excellence. As a diversified
              business entity, we specialize in areas that contribute to the
              growth and development of local and global markets, with a strong
              focus on quality and community impact.
            </p>
          </div>

          {/* <div
            className="mission  flex xl:flex xl:flex-row flex-col justify-center gap-10"
            data-aos="zoom-in"
          >
            <div className="mission-statement  bg-[#F8ECC8]  p-5 py-28 rounded-[20px]">
              <img src="Images/mission-star.svg" alt="" />
              <h2 className="xl:text-3xl mt-5 text-3xl">Our Vision</h2>

              <p className="xl:text-2xl mt-4 leading-loose z-40 text-xl">
                To be a global leader in delivering innovative, sustainable, and
                high-quality solutions across industries while fostering
                economic growth and empowering communities.
              </p>
            </div>
            <div
              className="mission-statement  bg-[#F8ECC8] p-5 py-28 rounded-[20px] "
              data-aos="zoom-in"
            >
              <img src="Images/mission-star.svg" alt="" />
              <h2 className="xl:text-3xl mt-5 text-3xl">Our Mission</h2>

              <p className="xl:text-2xl mt-4 leading-loose z-40 text-xl">
                To provide exceptional services and products that meet global
                standards, create sustainable value for our stakeholders, and
                leave a lasting positive impact on society.
              </p>
            </div>
          </div> */}
          <div
            className="mission flex flex-col xl:flex-row justify-center gap-10"
            data-aos="zoom-in"
          >
            <div className="mission-statement bg-[#F8ECC8] p-5 py-10 md:py-20 xl:py-28   rounded-[20px]">
              <img src="Images/mission-star.svg" alt="" />
              <h2 className="text-2xl md:text-3xl xl:text-4xl mt-5">
                Our Vision
              </h2>
              <p className="text-base md:text-lg xl:text-xl mt-4 leading-relaxed">
                To be a global leader in delivering innovative, sustainable, and
                high-quality solutions across industries while fostering
                economic growth and empowering communities.
              </p>
            </div>
            <div
              className="mission-statement bg-[#F8ECC8] p-5 py-10 md:py-20 xl:py-28 rounded-[20px]"
              data-aos="zoom-in"
            >
              <img src="Images/mission-star.svg" alt="" />
              <h2 className="text-2xl md:text-3xl xl:text-4xl mt-5">
                Our Mission
              </h2>
              <p className="text-base md:text-lg xl:text-xl mt-4 leading-relaxed">
                To provide exceptional services and products that meet global
                standards, create sustainable value for our stakeholders, and
                leave a lasting positive impact on society.
              </p>
            </div>
          </div>

          <div className="our-value">
            <div className="industries-served   pb-16 pt-36">
              <h2 className="xl:text-5xl capitalize mb-5 text-3xl">
                our value
              </h2>

              <div className="indus-con flex flex-wrap w-[100%]  gap-9">
                <div
                  className="indus xl:w-[30%] md:w-[100%] py-16 bg-[#F1F5E4] p-5 rounded-[25px]"
                  data-aos="zoom-in"
                >
                  <img src="Images/star.svg" alt="" className="mb-5" />
                  <h2 className="text-2xl mb-3">Innovation</h2>
                  <p className=" text-xl">
                    Constantly evolving to provide cutting-edge solutions.{" "}
                  </p>
                </div>

                <div
                  className="indus xl:w-[30%] md:w-[100%] py-16 bg-[#F1F5E4] p-5 rounded-[25px]"
                  data-aos="zoom-in"
                >
                  <img src="Images/star.svg" alt="" className="mb-5" />
                  <h2 className="text-2xl mb-3">Sustainability</h2>
                  <p className=" text-xl">
                    Ensuring our processes and products protect the environment{" "}
                  </p>
                </div>
                <div
                  className="indus xl:w-[30%] md:w-[100%] py-16 bg-[#F1F5E4] p-5 rounded-[25px]"
                  data-aos="zoom-in"
                >
                  <img src="Images/star.svg" alt="" className="mb-5" />
                  <h2 className="text-2xl mb-3">Integrity</h2>
                  <p className=" text-xl">
                    Operating with transparency, honesty, and fairness.{" "}
                  </p>
                </div>
                <div
                  className="indus xl:w-[30%] md:w-[100%] py-16 bg-[#F1F5E4] p-5 rounded-[25px]"
                  data-aos="zoom-in"
                >
                  <img src="Images/star.svg" alt="" className="mb-5" />
                  <h2 className="text-2xl mb-3">Empowerment</h2>
                  <p className=" text-xl">
                    Supporting communities, employees, and stakeholders in
                    achieving their goals.
                  </p>
                </div>
                <div
                  className="indus xl:w-[30%] md:w-[100%] py-16 bg-[#F1F5E4] px-10 rounded-[25px]"
                  data-aos="zoom-in"
                >
                  <img src="Images/star.svg" alt="" className="mb-5" />
                  <h2 className="text-2xl mb-3">Excellence</h2>
                  <p className="text-xl">
                    Delivering top-tier quality in everything we do.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="team">
            <div className="industries-served pb-16 pt-36">
              <h2 className="xl:text-5xl capitalize mb-10 text-3xl">
                Meet the Team
              </h2>

              <div className="indus-con flex flex-wrap w-full gap-5 text-white">
                {[
                  {
                    imgSrc: "Images/ceo.jpeg",
                    name: "Taiwo Oseola",
                    title: "MD/CEO",
                  },
                  {
                    imgSrc: "Images/sales.jpeg",
                    name: " Funmilayo Adesuyi ",
                    title: "Sales Director",
                  },
                  {
                    imgSrc: "Images/finan.jpeg",
                    name: "Mayomi Modeyin",
                    title: "Financial Director",
                  },
                  {
                    imgSrc: "Images/admin.jpeg",
                    name: "Ruth Badejo",
                    title: "Admin Director",
                  },
                  {
                    imgSrc: "Images/gen.jpeg",
                    name: "Oluwole Fagbenro",
                    title: "General Manager",
                  },
                  {
                    imgSrc: "Images/logimanager.jpeg",
                    name: "Cornelius Odofin",
                    title: "Logistics Manager",
                  },
                  {
                    imgSrc: "Images/field.jpg",
                    name: "Idowu Adesuyi",
                    title: "Field Manager",
                  },
                ].map((member, index) => (
                  <div
                    key={index}
                    className="indus xl:w-[30%] md:w-full"
                    data-aos="zoom-in"
                  >
                    <img
                      src={member.imgSrc}
                      alt={member.name}
                      className="h-[70%] w-full"
                    />
                    <div className="bg-[#277A1B] text-center">
                      <p className="text-[20px] pt-5 mb-3 font-semibold">
                        {member.name}
                      </p>
                      <h2 className="text-2xl font-semibold pb-3">
                        {member.title}
                      </h2>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="partner w-full" data-aos="fade-right">
            <h1 className="text-2xl md:text-4xl xl:w-2/5 mb-5 font-semibold leading-relaxed mt-12 md:mt-24 lg:mt-32 xl:mt-48">
              Partner with Us for Growth and Excellence
            </h1>
            <p className="text-base md:text-lg xl:text-xl xl:w-4/5 mb-5 leading-relaxed">
              Whether you're a farmer seeking a reliable offtaker, a business in
              need of top-quality processed farm produce, or an exporter aiming
              for international standards, Tricord Depot is your trusted
              partner. Together, we bridge the gap between farms and global
              markets.
            </p>
            <h3 className="text-xl md:text-2xl lg:text-3xl font-semibold">
              Ready to Work with Us?
            </h3>
            <p className="text-base md:text-lg xl:text-xl xl:w-3/5 mt-3 mb-10 leading-relaxed">
              Reach out to discuss how we can support your farming or business
              goals. Whether it's processing, financing, or exporting, your
              journey to success starts with Tricord Depot. Let’s grow together!
            </p>
            <div className="partner-btns flex items-center gap-4 pb-10 md:pb-20">
              <Link
                className="rounded-lg border-2 px-4 md:px-6 py-2 md:py-3 border-[#277A1B] text-[#277A1B] text-sm md:text-lg"
                to="mailto:contact@tricordglobal.com.ng"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Aboutus;
