import React from "react";
import { Link } from "react-router-dom";
import "../styles/footer.css";

const Footer = () => {
  return (
    <>
      <div className="bg-[#0B2C07]  text-white border-b-[0.5px] pb-5">
        <section className="footer-con   xl:flex items-center justify-between  border-b-[0.5px]  ">
          <div className="logo xl:w-[40%] pt-5 ">
            <Link>
              {" "}
              <img src="Images/footer-logo.svg" alt="" width={200} />{" "}
            </Link>
            <p className="mt-5 w-[80%] xl:text-xl">
              Empowering Agriculture, Sustainable Packaging, and Organic Food
              Production
            </p>
            <div className="socials flex items-center mt-5 gap-4">
              <Link>
                <img src="Images/Facebook.svg" alt="" className="w-[30px]" />
              </Link>
              <Link>
                <img src="Images/Twitter.svg" alt="" className="w-[30px]" />
              </Link>
              <Link>
                <img src="Images/Vector.svg" alt="" className="w-[30px]" />
              </Link>
            </div>
          </div>
          <div className="quick-link my-10">
            <p className="xl:text-2xl font-semibold mb-5">
              <Link>Quick-Links</Link>{" "}
            </p>
            <p className="mb-5">
              {" "}
              <Link className="xl:text-lg mb-5" to="/">
                Home
              </Link>
            </p>
            <p className="mb-5">
              <Link className="xl:text-lg mb-5" to="/">
                Services
              </Link>
            </p>
            <p className="mb-5">
              <Link
                className="xl:text-lg mb-5"
                to="mailto:contact@tricordglobal.com.ng"
              >
                Contact Us
              </Link>
            </p>
          </div>
          <div className="legal my-10">
            <p className="xl:text-2xl font-semibold mb-5">Legal</p>
            <p className="xl:text-lg mb-5">Terms of service</p>
            <p className="xl:text-lg mb-5">Privacy policy</p>
            <p className="xl:text-lg mb-5">Cookies</p>
          </div>
          <div className="get-in-touch">
            <p className="xl:text-2xl font-semibold mb-5">Get in Touch</p>
            <p className="xl:text-lg mb-5">
              45,0ke Itura, Ondo , Ondo State, Nigeria
            </p>
            <p className="mb-5">
              <Link className="xl:text-lg mb-5" to="tel:07056058779">
                +2347056058779
              </Link>
            </p>
            <p className="mb-5">
              {" "}
              <Link
                className="xl:text-lg mb-5"
                to="mailto:contact@tricordglobal.com.ng"
              >
                contact@tricordglobal.com.ng
              </Link>
            </p>
          </div>
        </section>
        <p className="mt-10 text-center">
          ©2024 Tricord Global Resource Limited. All Rights Reserved.{" "}
        </p>
      </div>
    </>
  );
};

export default Footer;
