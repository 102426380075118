import React from "react";
import { Link } from "react-router-dom";
import "../styles/food.css";
import CountUp from "../components/CountUp";

const Food = () => {
  return (
    <>
      {/* <section className="food-inner-con ">
        <div
          className="text text-black  text-center flex
      flex-col items-center justify-center capitalize pb-24 text-white"
        >
          <p className="mb-6 xl:text-xl lg:text-2xl leading-snug pt-10 md:text-xl text-2xl ">
            welcome to Tricord Foods
          </p>
          <h2 className="xl:text-5xl xl:w-[70%] lg:text-5xl md:text-5xl text-4xl">
            Wholesome Goodness, Naturally Processed
          </h2>
          <p className="xl:text-xl mt-10 lg:text-2xl text-black xl:w-[80%]">
            At Tricord Food, we are committed to providing high-quality,
            naturally processed food products that nourish your body and satisfy
            your taste buds. While we aim to expand our product line, we are
            proud to introduce our flagship offering—premium plantain-based
            products.
          </p>
          <Link
            to="/t-form"
            className="bg-[#277A1B] py-4 px-6 rounded mt-5 mb-16 lg:text-xl md:text-xl text-2xl"
          >
            Shop products
          </Link>
        </div>
      </section> */}
      <section className="hero-con bg-[rgb(248,250,240)] ">
        <div
          className="hero-inner-con xl:flex py-24 md:gap-24 text-white"
          data-aos="fade-right"
        >
          <div className="hero-text-con xl:w-[70%] sm:w-full text-left text-center mx-auto">
            <p className=" mb-3 md:text-[20px]"> welcome to Tricord Foods</p>
            <h2 className="xl:text-5xl  xl:leading-[65px] md:text-6xl sm:text-4xl md:leading-[85px] sm:leading-[70px]  md:leading-[70px] mb-3 font-medium text-2xl">
              Wholesome Goodness, Naturally Processed
            </h2>
            <p className="md:text-[25px] md:leading-10 xl:text-[20px]">
              At Tricord Food, we are committed to providing high-quality,
              naturally processed food products that nourish your body and
              satisfy your taste buds. While we aim to expand our product line,
              we are proud to introduce our flagship offering—premium
              plantain-based products.
            </p>
          </div>
        </div>
      </section>
      <section className="bg-[#F9FAF0]">
        <div className="service-info-box md:flex md:gap-5 md:justify-between gap-10 pt-[100px] translate-y-[-150px] ">
          <div
            className="info-box flex flex-col justify-center  items-center bg-white border-t-8 rounded-t-[15px] border-[var(--primary-green)] md:w-[40%]  sm:w-[100%] py-10 "
            data-aos="zoom-in"
          >
            <h2 className="xl:text-5xl  lg:text-4xl text-4xl">
              {" "}
              <CountUp start={0} end={1000} duration={3000} />
            </h2>
            <p className="text-center xl:text-xl ">Customers Nationwide</p>
          </div>

          <div
            className="info-box flex flex-col justify-center  items-center bg-white border-t-8 rounded-t-[15px] border-[var(--primary-green)] md:w-[40%]  sm:w-[100%] py-10 "
            data-aos="zoom-in"
          >
            <h2 className="xl:text-5xl text-4xl  lg:text-4xl">
              <CountUp start={0} end={95} duration={3000} />%
            </h2>
            <p className="text-center xl:text-xl">Satisfaction Rate</p>
          </div>
          <div
            className="info-box flex flex-col justify-center  items-center bg-white border-t-8 rounded-t-[15px] border-[var(--primary-green)]  md:w-[40%] sm:w-[100%] py-10"
            data-aos="zoom-in"
          >
            <h2 className="xl:text-5xl text-4xl  lg:text-4xl">
              <CountUp start={0} end={5000} duration={3000} />
            </h2>
            <p className="text-center lg:text-xl">deliveries</p>
          </div>
          <div
            className="info-box flex flex-col justify-center  items-center bg-white border-t-8 rounded-t-[15px] border-[var(--primary-green)]  md:w-[40%] sm:w-[100%] py-10"
            data-aos="zoom-in"
          >
            <h2 className="xl:text-5xl text-4xl  lg:text-4xl">
              <CountUp start={0} end={500} duration={3000} />+
            </h2>
            <p className="text-center xl:text-xl">Farm produce</p>
          </div>
        </div>
      </section>
      <section className="what-we-do text-[#0B2C07] bg-[rgb(248,250,240)] pb-20">
        <div data-aos="fade-right">
          <h2 className="md:text-6xl pt-20 mb-3 text-capitalize text-3xl md:text-center">
            What We Do:
          </h2>
        </div>

        <section className="whoo">
          <div data-aos="fade-right">
            <p className="md:text-2xl text-xl mb-10 leading-normal">
              We specialize in processing a wide range of food products,
              starting with our premium plantain-based products. Whether you're
              looking for fresh plantains or processed options like plantain
              flour, Tricord Food offers products that meet the highest
              standards of quality and taste.
            </p>
          </div>

          <div className="team">
            <div className="pb-16 pt-36">
              <div className="indus-con flex flex-wrap gap-6 justify-center text-white">
                {[
                  "tricord-maize.jpeg",
                  "tricordplantain.jpeg",
                  "farming.jpeg",
                  "f-work.jpeg",

                  "farm(28).jpg ",
                  "staff.jpeg",
                ].map((image, index) => (
                  <div
                    key={index}
                    className="indus  md:w-[30%] w-[100%]"
                    data-aos="zoom-in"
                  >
                    <img
                      src={`Images/${image}`}
                      alt={`Image ${index + 1}`}
                      className="w-full h-[400px] object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div
            className="mission  flex xl:flex xl:flex-row flex-col justify-center gap-10"
            data-aos="zoom-in"
          >
            <div className="mission-statement  bg-[#F8ECC8]  p-5 py-28 rounded-[20px]">
              <img src="Images/mission-star.svg" alt="" />
              <h2 className="xl:text-3xl mt-5 text-3xl">Our Vision</h2>

              <p className="xl:text-2xl mt-4 leading-loose z-40 text-xl">
                To be a global leader in delivering innovative, sustainable, and
                high-quality solutions across industries while fostering
                economic growth and empowering communities.
              </p>
            </div>
            <div
              className="mission-statement  bg-[#F8ECC8] p-5 py-28 rounded-[20px] "
              data-aos="zoom-in"
            >
              <img src="Images/mission-star.svg" alt="" />
              <h2 className="xl:text-3xl mt-5 text-3xl">Our Mission</h2>

              <p className="xl:text-2xl mt-4 leading-loose z-40 text-xl">
                To provide exceptional services and products that meet global
                standards, create sustainable value for our stakeholders, and
                leave a lasting positive impact on society.
              </p>
            </div>
          </div>

          <div className="our-value">
            <div className="industries-served   pb-16 pt-36">
              <h2
                className="xl:text-5xl capitalize mb-5 text-3xl"
                data-aos="fade-right"
              >
                our value
              </h2>

              <div className="indus-con flex flex-wrap w-[100%]  gap-9">
                <div
                  className="indus xl:w-[30%] md:w-[100%] py-16 bg-[#F1F5E4] p-5 rounded-[25px]"
                  data-aos="zoom-in"
                >
                  <img src="Images/star.svg" alt="" className="mb-5" />
                  <h2 className="text-2xl mb-3">Innovation</h2>
                  <p className=" text-xl">
                    Constantly evolving to provide cutting-edge solutions.{" "}
                  </p>
                </div>

                <div
                  className="indus xl:w-[30%] md:w-[100%] py-16 bg-[#F1F5E4] p-5 rounded-[25px]"
                  data-aos="zoom-in"
                >
                  <img src="Images/star.svg" alt="" className="mb-5" />
                  <h2 className="text-2xl mb-3">Sustainability</h2>
                  <p className=" text-xl">
                    Ensuring our processes and products protect the environment{" "}
                  </p>
                </div>
                <div
                  className="indus xl:w-[30%] md:w-[100%] py-16 bg-[#F1F5E4] p-5 rounded-[25px]"
                  data-aos="zoom-in"
                >
                  <img src="Images/star.svg" alt="" className="mb-5" />
                  <h2 className="text-2xl mb-3">Integrity</h2>
                  <p className=" text-xl">
                    Operating with transparency, honesty, and fairness.{" "}
                  </p>
                </div>
                <div
                  className="indus xl:w-[30%] md:w-[100%] py-16 bg-[#F1F5E4] p-5 rounded-[25px]"
                  data-aos="zoom-in"
                >
                  <img src="Images/star.svg" alt="" className="mb-5" />
                  <h2 className="text-2xl mb-3">Empowerment</h2>
                  <p className=" text-xl">
                    Supporting communities, employees, and stakeholders in
                    achieving their goals.
                  </p>
                </div>
                <div
                  className="indus xl:w-[30%] md:w-[100%] py-16 bg-[#F1F5E4] px-10 rounded-[25px]"
                  data-aos="zoom-in"
                >
                  <img src="Images/star.svg" alt="" className="mb-5" />
                  <h2 className="text-2xl mb-3">Excellence</h2>
                  <p className="text-xl">
                    Delivering top-tier quality in everything we do.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="product">
            <div className="pb-16 pt-36">
              <h2
                className="xl:text-5xl capitalize mb-5 text-3xl"
                data-aos="fade-right"
              >
                our products
              </h2>
              <p
                className="md:text-2xl text-xl mb-10 leading-normal"
                data-aos="fade-right"
              >
                Tricord Food is dedicated to providing quality and affordable
                food products tailored for local consumption. With a focus on
                innovation and accessibility, we process a range of staple
                items, including plantain chips, plantain flour, yam flour,
                custard, garri, and various pastas. Our mission is to support
                healthy living by offering nutritious and versatile products
                that fit seamlessly into everyday meals.
              </p>
              <div className="indus-con flex flex-wrap gap-6 justify-center text-white">
                {[
                  "tricordplantain.jpeg",
                  "yamflour.webp",
                  "plantain chip.jpeg",
                  "plantain flour.jpeg",
                ].map((image, index) => (
                  <div
                    key={index}
                    className="indus  md:w-[30%] w-[100%]"
                    data-aos="zoom-in"
                  >
                    <img
                      src={`Images/${image}`}
                      alt={`Image ${index + 1}`}
                      className="w-full h-[400px] object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="partner w-[100%]" data-aos="fade-right">
            <h1 className="md:text-4xl xl:w-[35%] mb-5 font-semibold leading-[3rem] mt-48 text-3xl">
              Partner with Us for Growth and Excellence{" "}
            </h1>
            <p className="text-xl xl:w-[80%] mb-5 leading-[2rem]">
              Whether you're a farmer seeking a reliable offtaker, a business in
              need of top-quality processed farm produce, or an exporter aiming
              for international standards, Tricord Depot is your trusted
              partner. Together, we bridge the gap between farms and global
              markets.
            </p>
            <h3 className="md:text-3xl  text-2xl font-semibold ">
              Ready to Work with Us?
            </h3>
            <p className="text-xl xl:w-[70%] mt-3 mb-10">
              Reach out to discuss how we can support your farming or business
              goals. Whether it's processing, financing, or exporting, your
              journey to success starts with Tricord Depot. Let’s grow together!
            </p>
            <div className="partner-btns flex items-center gap-4 pb-28">
              <Link
                className=" rounded-[20px] border-2 px-6 py-3 border-[#277A1B] text-[#277A1B] md:text-xl text-[12px]"
                to="mailto:contact@tricordglobal.com.ng"
              >
                Contact Us
              </Link>
              <div className="flex flex-col items-center justify-center "></div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Food;
