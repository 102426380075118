import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import React, { useEffect } from "react";
import Layout from './components/Layout';
import Logistics from './pages/Logistics';
import Home from './pages/Home';
import FormPage from './pages/FormPage';
import Food from './pages/Food';
import Packaging from './pages/Packaging';
import Depot from './pages/Depot';
import Aboutus from './pages/Aboutus';
import AOS from "aos";
import "aos/dist/aos.css";

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1500,
    });
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/logi" element={<Logistics />} />
          <Route path="/t-form" element={<FormPage />} />
          <Route path="/food" element={<Food />} />
          <Route path="/packaging" element={<Packaging />} />
          <Route path="/depot" element={<Depot />} />
          <Route path="/Aboutus" element={<Aboutus />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
