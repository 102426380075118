import React, { useState } from "react";
import Paystack from "@paystack/inline-js";
import "../styles/formpage.css";
import { Link } from "react-router-dom";

const FormPage = () => {
  const paystackKey = process.env.REACT_APP_PAYSTACK_KEY;
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    amount: "",
    selectOption2: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  function handleSubmit(e) {
    e.preventDefault();

    const popup = new Paystack();
    console.log(formData);

    popup.checkout({
      key: paystackKey,
      email: formData.email,
      amount: formData.amount,
      onSuccess: (transaction) => {
        console.log(transaction);
      },
      onLoad: (response) => {
        console.log("onLoad: ", response);
      },
      onCancel: () => {
        console.log("onCancel");
      },
      onError: (error) => {
        console.log("Error: ", error.message);
      },
    });
  }

  return (
    <>
      <div className="form-con pb-16">
        <Link
          to="/logi"
          className="text-2xl  block ml-5 capitalize text-[#277A1B] pt-5 pb-5"
        >
          {" "}
          back{" "}
        </Link>
        <div className=" md:w-[50%] w-[100%] m-[auto] bg-white rounded-[20px] md:py-16 md:pb-15 py-7 ">
          <h2 className="text-center capitalize text-2xl mb-5 mt-5 text-[]">
            book a truck
          </h2>
          <form action="" className="w-[70%] m-[auto]" onSubmit={handleSubmit}>
            <div className="input-con">
              <label htmlFor="" className="block">
                Fullname:
              </label>
              <input
                required
                className="text shadow border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
              />
            </div>
            <div className="input-con">
              <label htmlFor="email" className="block">
                Email:
              </label>
              <input
                required
                name="email"
                className="text shadow border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                type="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="input-con">
              <label htmlFor="" className="block">
                Mobile-Number:
              </label>
              <input
                required
                className="text shadow border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                type="number"
              />
            </div>
            <div className="input-con">
              <label htmlFor="" className="block">
                worth of package:
              </label>
              <input
                required
                className="text shadow border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                type="number"
              />
            </div>
            <div className=" input-con mb-4">
              <label className="block " htmlFor="amount">
                Location:
              </label>
              <select
                name="amount"
                value={formData.selectOption1}
                onChange={handleChange}
                required
                className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
              >
                <option value="">Choose an option</option>
                <option value="50000000">LAG-IBADAN----#500000</option>
                <option value="Option2">Option 2</option>
                <option value="Option3">Option 3</option>
              </select>
            </div>
            <div className="">
              <button
                type="submit"
                className="w-[100%] bg-[#277A1B] py-5 text-white captalize"
              >
                Make Payment
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormPage;
